<template>
	<div>
		<el-row :gutter="20">
			<el-col :span="2">
				<div class="grid-content bg-purple">
					<el-row >
						<el-button size="mini" type="primary"  icon="el-icon-plus" @click="add()">添加</el-button>
					</el-row>
				</div>
			</el-col>
			<el-col :span="22">
				<div class="grid-content bg-purple-light">
					<div class="search">
						<el-form :inline="true" :model="formInline" class="demo-form-inline">
							<el-form-item label="角色名称">
								<el-input v-model="formInline.name" placeholder="角色名称"></el-input>
							</el-form-item>
							<el-form-item label="状态">
								<el-select v-model="formInline.status" placeholder="状态">
								<el-option label="开启" value="1"></el-option>
								<el-option label="关闭" value="0"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item>
								<el-button type="primary" @click="getList()">查询</el-button>
								<el-button @click="reset">重置</el-button>
							</el-form-item>
						</el-form>
					</div>
				</div>
			</el-col>
		</el-row>
		<el-table  v-loading="loading" :header-cell-style="{ background: '#F5F7FA', color: '#606266' }" ref ="treeTable" :data="data" lazy style="width: 100%;"  border="" row-key="id" :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
			<el-table-column type="selection" width="55"></el-table-column>
			<!-- <el-table-column align="center" prop="id" label="序号" sortable width="80"  type=""></el-table-column> -->
			<el-table-column align="center" prop="name" label="角色名称"></el-table-column>

			<el-table-column align="center" width="100"  label="状态" type="" >
				<template slot-scope="scope" >
					<el-switch :disabled="scope.row.admin_id==0" v-model="scope.row.status" :active-value="1" :inactive-value="0" @change="changeSwitch(scope.row)"></el-switch>
				</template>
			</el-table-column>
			<el-table-column align="center" prop="created_at" label="创建时间"></el-table-column>
			<el-table-column align="center" fixed="right" label="操作" width="260">
				<template slot-scope="scope">
<!--          :disabled="scope.row.admin_id==0"-->
          <el-button  @click="rule(scope.row.id)" type="text" size="small"><i class="el-icon-edit-outline"></i>分配权限</el-button>
					<el-button :disabled="scope.row.admin_id==0" @click="modify(scope.row)" type="text" size="small"><i class="el-icon-edit-outline"></i>编辑</el-button>

<!--          <el-button :disabled="scope.row.admin_id==0"  type="text" size="small"><i class="el-icon-edit"></i>删除</el-button>-->
          <el-popconfirm :disabled="scope.row.admin_id==0" title="确定要删除这条数据吗" @confirm="deletes(scope.row)">
            <el-link :disabled="scope.row.admin_id==0" style="font-size: 13px;margin: 10px" :underline="false" type="danger" slot="reference" > <i class="el-icon-delete"></i>删除</el-link>
					</el-popconfirm>
				</template>
			</el-table-column>
		</el-table>
		<Modal v-model="router"  class-name="order-modal" @on-cancel="ModalCancel"  :mask="false" :title="modalTitle">
		       <router-view @routerCancel="ModalCancel"></router-view>
		</Modal>
	</div>
</template>

<script>
	import {request} from "@/library/request.js";
	// import create from "components/system/role/create.vue"
	// import edit from "components/system/role/edit.vue"
	// import jurisdiction from "components/system/role/jurisdiction.vue"
	export default {
		components:{
			// create,
			// edit,
			// jurisdiction
		},
		data(){
			return{
				data:[],
				router:false,
				loading: true,
				modalTitle:"",
				timer:"",
				formInline:{
					name:"",
					status:"",
				},
				id:0,
			}
		},
		methods: {
			getList(){

				this.$Loading.start();
				var search = {
					searchParams:{
						'name-%%':this.formInline.name,
						'status-=':this.formInline.status,
					}
				}
				return request({
					url:'system/role/list',
					method:"get",
					params:search
				}).then(response  => {
					this.$Loading.finish();
					this.loading = false
					this.data = response.data
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
			},
			prev(value){
				this.currentPage = value
				this.getList()
			},
			next(value){
				this.currentPage = value
				this.getList()
			},
			CurrentChange(value){
				this.currentPage = value
				this.getList()
			},
			reset(){
				this.formInline.name=""
				this.formInline.status=""
				this.getList()
			},
			changeSwitch(data){
				let param = new URLSearchParams()
				param.append('status', data.status)
				return request({
					url:'system/role/status/'+data.id,
					method:"post",
					data:param
				}).then(response  => {
					if(response.code!=200){
						this.$Message.error(response.msg)
					}
				}).then(err => {
					if(err){
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})	
			},
			add(){
				this.modalTitle = "创建角色"
				this.router=true
				this.$router.push({
					name:'role/create',
				})
			},
			createForm(res){
				this.create=res
				this.getList();
			},
			deletes(row){
        var that = this
				return request({
					url:'system/role/delete/'+row.id,
					method:"post",
				}).then(response  => {
					if(response.code==200){
            that.getList()
					}
					
					
				}).then(err => {
					if(err){
						this.loading = false
						this.$Loading.error();
						this.$Message.error("系统异常，请稍后重试")
					}
				})
				
			},
			modify(rows){
				this.modalTitle = "编辑角色"
				this.router=true
				this.$router.push({
					name:'role/edit',
					params:{id:rows.id,pid:rows.pid}
				})
			},
			ModalCancel(res){
				this.router=false
				this.$router.push({
					name:'system/role'
				})
				if(res){
					this.getList()
				}
			},
			rule(id){
				this.modalTitle = "分权权限"
				this.router=true
				this.$router.push({
					name:'role/jurisdiction',
					params:{id:id}
				})
			}
		},
		mounted() {
			var route =this.$route.path;
			if(route!="/system/role"){
				this.ModalCancel()
			}

			this.getList()
		}
	}
</script>

<style scoped>
	@import url('/css/app.css');
</style>
<style>

	.order-modal .ivu-modal-body{
		max-height: 600px;
		overflow-y: auto;
	}

</style>